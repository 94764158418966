import React from 'react';

export default ({
  auth,
}) => (
  <div className="Home">
    <div className="columns">
      <div className="column col-mx-auto col-8 col-md-10 col-sm-12 col-xs-12">
        <div className="hero hero-lg bg-primary">
          <div className="hero-body">
            <div>
              <h1>Snapdoc</h1>
              {auth.finished && auth.user ? (
                <p>{`Welcome back ${auth.user.displayName}!`}</p>
              ) : (
                <p>A service to create and share content in a snap!</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);
