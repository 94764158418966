import React, { createContext } from 'react';
import app from 'firebase/app';
import 'firebase/firestore';
import 'firebase/analytics';
import 'firebase/auth';

const FirebaseContext = createContext(null);
export { FirebaseContext };

export default ({ children }) => {
  if (!app.apps.length) {
    app.initializeApp({
      apiKey: 'AIzaSyCQYkL5iPzITiJbRQj39Ax1IGL_2OS1uIE',
      // authDomain: 'markdown-renderer-04102020.firebaseapp.com',
      // https://stackoverflow.com/a/65280457
      authDomain: 'snapdoc.fyi',
      databaseURL: 'https://markdown-renderer-04102020.firebaseio.com',
      projectId: 'markdown-renderer-04102020',
      storageBucket: 'markdown-renderer-04102020.appspot.com',
      messagingSenderId: '168454946053',
      appId: '1:168454946053:web:d030acfc4c44ecc850cb6c',
      measurementId: 'G-SL2SXQVQFD',
    });
    app.firestore().enablePersistence({
      synchronizeTabs: true,
    });
  }
  return (
    <FirebaseContext.Provider value={app}>
      { children }
    </FirebaseContext.Provider>
  );
};
