import React from 'react';
import { useHistory, Link, useLocation } from 'react-router-dom';

import firebase from 'firebase/app';
import 'firebase/auth';

import Button from './Button';

/**
 * props = {
 *   firebaseAuth: auth,
 *   auth: {},
 * }
 */
export default ({
  firebaseAuth,
  auth,
}) => {
  const location = useLocation();
  const history = useHistory();

  if (location.pathname.startsWith('/public/')) {
    // don't display nav on public pages
    return null;
  }

  const signIn = () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    provider.addScope('https://www.googleapis.com/auth/userinfo.email');
    firebaseAuth.signInWithRedirect(provider);
  };

  const signOut = async () => {
    await firebaseAuth.signOut();
    history.push('/');
  };

  return (
    <header className="navbar">
      <section className="navbar-section">
        <Link to="/" className="navbar-brand mr-2 text-bold hide-sm">snapdoc.fyi</Link>
        {auth.user && (
          <Link to="/catalog" className={`btn ${location.pathname === '/meals' ? 'btn-primary' : 'btn-link'}`}>
            <i className="fas fa-book-open" />
            <span className="hide-lg" style={{ marginLeft: '10px' }}>My Pages</span>
          </Link>
        )}
      </section>
      <section className="navbar-center" />
      <section className="navbar-section">
        {auth.finished && (
          <div>
            {auth.user ? (
              <Button
                className="btn btn-link"
                onClick={signOut}
                text={<i className="fas fa-sign-out-alt" />}
              />
            ) : (
              <Button
                className="btn btn-link"
                onClick={signIn}
                text={<i className="fas fa-sign-in-alt" />}
              />
            )}
          </div>
        )}
      </section>
    </header>
  );
};
