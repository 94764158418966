/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import Display from './utils/Display';
import Button from './utils/Button';

export default ({
  firestore,
  auth,
}) => {
  const params = useParams();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(true);
  const [pageTitle, setPageTitle] = useState('');
  const [pageTitleError, setPageTitleError] = useState(null);
  const [pageText, setPageText] = useState('');
  const [pageTextError, setPageTextError] = useState(null);
  const [pageStyle, setPageStyle] = useState(null);

  useEffect(() => {
    if (!auth.user
      || !params.pageId) {
      return;
    }
    const fetchData = async () => {
      const workingDoc = await firestore.collection('pages').doc(params.pageId)
        .collection('working').doc('0')
        .get();
      if (!workingDoc.exists) {
        return;
      }
      setPageTitle(workingDoc.data().title);
      setPageTitleError(null);
      setPageText(workingDoc.data().text);
      setPageTextError(null);
      setPageStyle(workingDoc.data().style);
      setIsLoading(false);
    };
    fetchData();
  }, [firestore, auth, params.pageId]);

  const savePage = async () => {
    if (!auth.user
      || !params.pageId) {
      return;
    }
    await firestore.collection('pages').doc(params.pageId)
      .collection('working').doc('0')
      .update({
        title: pageTitle || (`Page ${new Date().toLocaleString()}`),
        text: pageText || '',
        lastUpdatedOn: new Date(),
        styleName: pageStyle || 'default',
        authorUid: auth.user.uid,
      });
  };
  const publishPage = async () => {
    if (!auth.user
      || !params.pageId
      || !pageText
      || !pageTitle) {
      return;
    }
    await savePage();
    // create a revision
    const revision = {
      text: pageText,
      title: pageTitle,
      createdOn: new Date(),
      styleName: pageStyle || 'default',
      authorUid: auth.user.uid,
    };
    const pageRef = firestore.collection('pages').doc(params.pageId);
    const revisionRef = pageRef.collection('revisions').doc();
    revisionRef.set(revision); // don't wait for it
    await pageRef.update({
      published: {
        revisionId: revisionRef.id,
        text: revision.text,
        title: revision.title,
        styleName: revision.styleName,
      },
    });
    history.push('/catalog');
  };

  if (isLoading) {
    return (<div />);
  }

  return (
    <div className="EditorPage">
      <div className="columns">
        <div className="column col-6 col-md-12 text-break">
          <label className="form-label">Title</label>
          <input
            className={pageTitleError ? 'form-input is-error' : 'form-input'}
            value={pageTitle}
            onChange={(event) => {
              setPageTitleError(null);
              setPageTitle(event.target.value);
            }}
            type="text"
          />
        </div>
        <div className="column col-6 col-md-12 text-break">
          <label className="form-label">Style</label>
          <select
            className="form-input"
            value={pageStyle}
            onChange={(event) => {
              setPageStyle(event.target.value);
            }}
          >
            <option value="default">Default</option>
            <option value="black">Black</option>
          </select>
        </div>
      </div>
      <br />
      <div
        className="columns"
        style={{
          minHeight: '80vh',
        }}
      >
        <div className="column col-6 col-md-12 text-break">
          <textarea
            className={pageTextError ? 'form-input is-error' : 'form-input'}
            style={{ height: '100%' }}
            value={pageText}
            rows="10"
            onChange={(event) => {
              setPageTextError(null);
              setPageText(event.target.value);
            }}
          />
        </div>
        <div className="column col-6 col-md-12 text-break">
          <div style={{ height: '100%' }}>
            <Display
              markdown={pageText}
              styleName={pageStyle}
              padding
            />
          </div>
        </div>
      </div>
      <br />
      <div className="columns col-oneline">
        <div className="column col-12">
          <div className="btn-group btn-group-block btn-group-lg">
            <Button
              className="btn"
              onClick={savePage}
              text="Save"
            />
            <Button
              className="btn"
              onClick={publishPage}
              text="Publish"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
