/* eslint-disable no-undef */
import React, { useContext, useEffect, useState } from 'react';
import {
  BrowserRouter, Switch, Route, Redirect,
} from 'react-router-dom';

import { FirebaseContext } from './utils/firebase';
import 'firebase/firestore';

import CatalogPage from './CatalogPage';
import DisplayPage from './DisplayPage';
import EditorPage from './EditorPage';
import HomePage from './HomePage';

import Nav from './utils/Nav';

import './App.css';

export const createUser = (userRef, authUser) => (
  userRef.set({
    uid: authUser.uid,
    displayName: authUser.displayName,
    photoURL: authUser.photoURL,
    email: authUser.email,
    pages: {},
  })
);

const ProtectedRoute = ({ hasAccess, children, ...rest }) => {
  if (!hasAccess) {
    return <Redirect to="/" />;
  }
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Route {...rest}>
      {children}
    </Route>
  );
};

export default () => {
  const firebase = useContext(FirebaseContext);

  let lastLoadTime = Date.now();
  window.onblur = () => { lastLoadTime = Date.now(); };
  window.onfocus = () => Date.now() > lastLoadTime + 10 * 60 * 1000 && window.location.reload();

  // auth listener
  const [auth, setAuth] = useState({
    finished: false,
    user: null,
  });
  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      setAuth({
        finished: true,
        user,
      });
    });
    return () => { unsubscribe(); };
  }, [firebase]);

  // user listener (currently only to create a user if not exists)
  useEffect(() => {
    if (!auth || !auth.user) {
      return undefined;
    }
    const userRef = firebase.firestore().collection('users').doc(auth.user.uid);
    const unsubscribe = userRef
      .onSnapshot((ss) => {
        if (!ss.exists) {
          return createUser(userRef, auth.user);
        }
        return undefined;
      });
    return () => unsubscribe();
  }, [firebase, auth]);

  const hasLoggedIn = !auth.finished || (auth.user && auth.user.uid);
  return (
    <BrowserRouter>
      <Nav
        firebaseAuth={firebase.auth()}
        auth={auth}
      />
      <div className="columns">
        <div className="column col-12 col-mx-auto">
          <Switch>
            <ProtectedRoute
              exact
              hasAccess={hasLoggedIn}
              path="/catalog"
            >
              <CatalogPage
                firestore={firebase.firestore()}
                auth={auth}
              />
            </ProtectedRoute>
            <ProtectedRoute
              exact
              hasAccess={hasLoggedIn}
              path="/edit/:pageId"
            >
              <EditorPage
                firestore={firebase.firestore()}
                auth={auth}
              />
            </ProtectedRoute>
            <Route exact path="/public/:pageId/:revisionId">
              <DisplayPage
                firestore={firebase.firestore()}
                analytics={firebase.analytics()}
              />
            </Route>
            <Route exact path="/public/:pageId">
              <DisplayPage
                firestore={firebase.firestore()}
                analytics={firebase.analytics()}
              />
            </Route>
            <Route exact path="/">
              {hasLoggedIn ? (
                <Redirect to="/catalog" />
              ) : (
                <HomePage
                  auth={auth}
                />
              )}
            </Route>
          </Switch>
        </div>
      </div>
    </BrowserRouter>
  );
};
