/* eslint-disable no-undef */
import React, { useEffect } from 'react';
import Button from './Button';

/* eslimt-disable jsx-a11y/anchor-has-content */

export default (props) => {
  const {
    className,
    onModalClose,
    title,
    content,
  } = props;

  const onKeyDown = (e) => {
    if (e.keyCode === 27) {
      props.onModalClose();
    }
  };
  useEffect(() => {
    window.addEventListener('keydown', onKeyDown);
    return () => window.removeEventListener('keydown', onKeyDown);
  });

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      className={className}
      onKeyDown={(e) => {
        if (e.keyCode === 27) { props.onModalClose(); }
      }}
      // tabIndex='0' to allow key press on div
      // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
      tabIndex="0"
    >
      {/* eslint-disable-next-line */}
      <a href='#' className="modal-overlay" aria-label='Close'/>
      <div className="modal-container">
        <div className="modal-header">
          <Button
            onClick={onModalClose}
            className="btn btn-clear float-right"
          />
          <div className="modal-title h5 text-capitalize">{title}</div>
        </div>
        <div className="modal-body">
          {content}
        </div>
        {/* <div className="modal-footer">
          ...
        </div> */}
      </div>
    </div>
  );
};

/* eslimt-enable jsx-a11y/anchor-has-content */
