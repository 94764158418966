import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import Display from './utils/Display';

const STYLES = {};
STYLES.media = `
@media print {
  @page {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  ul {
    margin-top: 0px;
    margin-bottom: 5px;
  }
  h1 {
    font-size: 25px;
  }
  h2 {
    font-size: 17px;
    margin-top: 10px;
    margin-bottom: 0px;
  }
  p {
    padding: 0px;
    margin-top: 0px;
    margin-bottom: 5px;
    font-size: 12px;
  }
  li {
    font-size: 12px;
    line-height: 1em;
  }
}
`;
STYLES.default = `
`;
STYLES.black = `
body {
  background-color: black;
  color: #EEEEEE;
  font-family: Georgia, Palatino, serif;
}
h1 {
  font-size: 48px;
  font-weight: 400;
}
h3 {
  font-size: 24px;
  font-weight: 400;
}
a {
  color: #61bfc1;
  font-size: 16px;
}
a:visited {
  color: #466b6c;
}
`;

export default ({
  firestore,
  analytics,
}) => {
  const params = useParams();

  const [OTPStartTime, setOTPStartTime] = useState(Date.now());
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [text, setText] = useState(null);
  const [title, setTitle] = useState(null);
  const [styleName, setStyleName] = useState(null);

  // data fetcher
  useEffect(() => {
    if (!params.pageId) {
      return;
    }
    const fetchData = async () => {
      console.time('DisplayPage.fetchData');
      let pageDoc = null;
      let page = null;
      if (params.revisionId) {
        // if revision is specified, don't fetch general page, fetch revision
        pageDoc = await firestore.collection('pages').doc(params.pageId)
          .collection('revisions').doc(params.revisionId)
          .get();
        page = pageDoc.data();
      } else {
        // if revision is not specified, don't fetch revision, general page will fetch instead
        pageDoc = await firestore.collection('pages').doc(params.pageId)
          .get();
        if (!pageDoc.exists
          || !pageDoc.data().published) {
          setError('Page not found');
          return;
        }
        page = pageDoc.data().published;
      }
      setError(null);
      setText(page.text);
      setTitle(page.title);
      setStyleName(page.styleName);
      setIsLoading(false);
      console.timeEnd('DisplayPage.fetchData');
    };
    fetchData();
  }, [firestore, params.pageId, params.revisionId]);

  // analytics
  useEffect(() => {
    if (!analytics
      || isLoading // page is still loading
      || !OTPStartTime // startTime already consumed (we've already logged the event)
    ) {
      return;
    }
    const elapsedMs = Date.now() - OTPStartTime;
    setOTPStartTime(null);
    analytics.logEvent('page_load', {
      elapsedMs,
    });
  }, [analytics, OTPStartTime, isLoading]);

  if (error) {
    return (
      <div className="Display">
        <p>{error}</p>
      </div>
    );
  }
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  return (
    <div className="DisplayPage">
      <Helmet>
        <title>{title}</title>
        {styleName ? (
          <style type="text/css">{STYLES[styleName] + STYLES.media}</style>
        ) : urlParams.has('styleName') && (
          <style type="text/css">{STYLES[urlParams.get('styleName')] + STYLES.media}</style>
        )}
      </Helmet>
      <br />
      <div className="columns col-oneline">
        <div className={`column col-mx-auto col-auto col-sm-11 col-xs-12${isLoading ? ' loading loading-bg' : ''}`}>
          <Display markdown={text} />
        </div>
      </div>
      <br />
      <br />
    </div>
  );
};
