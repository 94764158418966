/* eslint-disable no-param-reassign */
import React, { useState, useEffect } from 'react';
import renderHTML from 'react-render-html';
import Showdown from 'showdown';
import { Helmet } from 'react-helmet';
import showdownMermaid from './showdown-mermaid';

const STYLES = {};
STYLES.default = '';
STYLES.black = `
.Display {
  background-color: black;
  color: #EEEEEE;
  font-family: Georgia, Palatino, serif;
}
.Display h1 {
  font-size: 48px;
  font-weight: 400;
}
.Display h3 {
  font-size: 24px;
  font-weight: 400;
}
.Display a {
  color: #61bfc1;
  font-size: 16px;
}
.Display a:visited {
  color: #466b6c;
}
`;

Showdown.extension('targetlink', () => [{
  type: 'lang',
  regex: /\[((?:\[[^\]]*]|[^[\]])*)]\([ \t]*<?(.*?(?:\(.*?\).*?)?)>?[ \t]*((['"])(.*?)\4[ \t]*)?\)/g,
  replace(wholematch, linkText, url, a, b, title) {
    let result = `<a href="${url}"`;
    if (typeof title !== 'undefined' && title !== '' && title !== null) {
      let trimedTitle = title.replace(/"/g, '&quot;');
      trimedTitle = Showdown.helper.escapeCharacters(trimedTitle, '*_', false);
      result += ` title="${trimedTitle}"`;
    }
    result += ' target="_blank"';
    result += `>${linkText}</a>`;
    return result;
  },
}]);

Showdown.subParser('githubCodeBlocks', (text, options, globals) => {
  // early exit if option is not enabled
  if (!options.ghCodeBlocks) {
    return text;
  }

  // eslint-disable-next-line no-underscore-dangle
  text = globals.converter._dispatch('githubCodeBlocks.before', text, options, globals);
  text += '¨0';
  text = text.replace(
    /(?:^|\n)(?: {0,3})(```+|~~~+)(?: *)([^\s`~]*?)(?:[ \t]*?)((?:\{[\S\t ]*\}|\[[\S\t ]*\])?)\n([\s\S]*?)\n(?: {0,3})\1/g,
    (wholeMatch, delim, language, langattr, codeblock) => {
      const end = options.omitExtraWLInCodeBlocks ? '' : '\n';

      // First parse the github code block
      codeblock = Showdown.subParser('encodeCode')(codeblock, options, globals);
      codeblock = Showdown.subParser('detab')(codeblock, options, globals);
      codeblock = codeblock.replace(/^\n+/g, ''); // trim leading newlines
      codeblock = codeblock.replace(/\n+$/g, ''); // trim trailing whitespace

      codeblock = `<pre><code${language
        ? ` class="${language} language-${language}"`
        : ''}${langattr
        ? ` data-lang='${langattr}'`
        : ''}>${codeblock}${end}</code></pre>`;

      codeblock = Showdown.subParser('hashBlock')(codeblock, options, globals);

      // Since GHCodeblocks can be false positives, we need to
      // store the primitive text and the parsed text in a global var,
      // and then return a token
      return (
        `\n\n¨G${globals.ghCodeBlocks.push({
          text: wholeMatch,
          codeblock,
        }) - 1}G\n\n`
      );
    },
  );

  // attacklab: strip sentinel
  text = text.replace(/¨0/, '');

  // eslint-disable-next-line no-underscore-dangle
  return globals.converter._dispatch('githubCodeBlocks.after', text, options, globals);
});

Showdown.extension('mermaid', showdownMermaid);

export default ({
  styleName,
  padding,
  markdown,
}) => {
  const [showdownConverter] = useState(new Showdown.Converter({
    tables: true,
    extensions: ['targetlink', 'mermaid'],
  }));

  const [reactElements, setReactElements] = useState((
    <div className="loading loading-lg" />
  ));
  useEffect(() => {
    const asyncLoader = async () => {
      const mdAsHtml = showdownConverter.makeHtml(markdown) || '';
      setReactElements(renderHTML(mdAsHtml));
    };
    asyncLoader();
  }, [showdownConverter, markdown]);

  return (
    <div className="Display" style={{ height: '100%' }}>
      <Helmet>
        <style type="text/css">
          {
            // remove all of the gray font when printing
            `@media print {
                @media print and (-webkit-min-device-pixel-ratio:0) {
                    body {
                        color: #000 !important;
                    }

                    h1, h2, h3, h4, h5, ul, li, p {
                        color: #000 !important;
                        -webkit-print-color-adjust: exact !important;
                    }
                }
            }`
          }
        </style>
        {styleName && (
          <style type="text/css">{STYLES[styleName]}</style>
        )}
      </Helmet>
      <div style={padding && { padding: '20px' }}>
        {reactElements}
      </div>
    </div>
  );
};
