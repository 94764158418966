import React from 'react';

export default (props) => {
  const {
    className,
    error,
    placeholder = '',
    value,
    onSubmit,
    onChange,
  } = props;

  const computedClassName = `${error ? 'is-error' : ''} ${className || 'form-input'}`;
  return (
    <input
      type="text"
      className={computedClassName}
      placeholder={placeholder}
      value={value}
      onKeyPress={(event) => {
        if (onSubmit && event.key === 'Enter') {
          onSubmit(event);
        }
      }}
      onChange={(event) => {
        if (onChange) {
          onChange(event);
        }
      }}
    />
  );
};
