import React, { useState, useEffect } from 'react';

export default ({
  onClick,
  disableDuration,
  tooltip,
  style = {},
  className = 'btn',
  text,
}) => {
  const [buttonEnabled, setButtonEnabled] = useState(true);

  useEffect(() => {
    if (buttonEnabled) {
      return () => undefined;
    }
    const timer = setTimeout(() => {
      setButtonEnabled(true);
    }, disableDuration || 500);
    return () => clearTimeout(timer);
  }, [buttonEnabled, disableDuration]);

  return (
    <button
      type="button"
      onClick={(event) => {
        event.stopPropagation();
        setButtonEnabled(false);
        onClick(event);
      }}
      data-tooltip={tooltip}
      style={style || {}}
      className={className
                + (buttonEnabled ? '' : ' loading')
                + (tooltip ? ' tooltip tooltip-bottom' : '')}
    >
      {text}
    </button>
  );
};
