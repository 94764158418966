/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-undef */
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

import './CatalogPage.css';

import Display from './utils/Display';

import Button from './utils/Button';
import Modal from './utils/Modal';
import Input from './utils/Input';

export const createNewPage = async (firestore, auth, title) => {
  if (!auth.user) {
    return;
  }
  const date = new Date();
  // create a new page
  const pageRef = firestore.collection('pages').doc();
  const workingRef = pageRef.collection('working').doc('0');

  await new Promise((resolve) => {
    pageRef
      .set({
        authorUid: auth.user.uid,
      })
      .then(() => {
        workingRef
          .set({
            createdOn: date,
            title,
            lastUpdatedOn: date,
          })
          .then(() => {
            resolve(true);
          });
      });
  });
  // add the page to the user who created it
  await firestore.runTransaction(async (trans) => {
    const userRef = firestore.collection('users').doc(auth.user.uid);
    const user = (await trans.get(userRef)).data();
    if (!user.pages) {
      user.pages = [];
    }
    user.pages[pageRef.id] = true;
    await trans.update(userRef, user);
  });
};

export const deletePage = async (firestore, auth, pageId) => {
  if (!auth.user) {
    return;
  }
  const pageRef = firestore.collection('pages').doc(pageId);
  const revisionsRef = pageRef.collection('revisions');
  const workingRef = pageRef.collection('working').doc('0');
  const userRef = firestore.collection('users').doc(auth.user.uid);
  const revisionDocs = await revisionsRef.get();

  await new Promise((resolve) => {
    Promise
      .all(revisionDocs.docs.map((doc) => doc.ref.delete()))
      .then(() => {
        workingRef
          .delete()
          .then(() => {
            pageRef
              .delete()
              .then(() => {
                resolve(true);
              });
          });
      });
  });
  await firestore.runTransaction(async (trans) => {
    const user = (await trans.get(userRef)).data();
    delete user.pages[pageId];
    await trans.update(userRef, user);
  });
};

export default ({
  firestore,
  auth,
}) => {
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(true);
  const [resetListeners, setResetListeners] = useState(false);
  const [showRevisionsForPageId, setShowRevisionsForPageId] = useState(null);

  // pages listener
  const [pages, setPages] = useState(null);
  useEffect(() => {
    if (!auth.user) {
      return () => undefined;
    }
    if (resetListeners) {
      setResetListeners(false);
    }
    const unsubscribe = firestore.collection('pages')
      .where('authorUid', '==', auth.user.uid)
      .onSnapshot(async (ss) => {
        const r = {};
        for (let i = 0; i < ss.docs.length; i += 1) {
          const pageDoc = ss.docs[i];
          const page = pageDoc.data();
          // eslint-disable-next-line no-await-in-loop
          const revisionDocs = await ss.docs[i].ref.collection('revisions')
            .orderBy('createdOn')
            .limit(10)
            .get();
          // eslint-disable-next-line no-await-in-loop
          const workingDoc = await ss.docs[i].ref.collection('working').doc('0')
            .get();
          r[pageDoc.id] = {
            page,
            working: workingDoc.data(),
            revisions: revisionDocs.docs,
          };
        }
        setPages(r);
        setIsLoading(false);
      });
    return () => { unsubscribe(); };
  }, [firestore, auth, resetListeners]);

  // on initial load, select the first page to expand
  useEffect(() => {
    if (!pages
      || !isLoading) {
      return;
    }
    if (!showRevisionsForPageId && Object.keys(pages).length > 0) {
      const sortedEntries = Object
        .entries(pages)
        .sort((a, b) => b[1].working.lastUpdatedOn.toDate() - a[1].working.lastUpdatedOn.toDate());
      setShowRevisionsForPageId(sortedEntries[0][0]);
    }
  }, [pages, isLoading, showRevisionsForPageId]);

  const viewPage = (pageId) => {
    window.open(`/public/${pageId}`, '_blank');
  };

  const editPage = (pageId) => {
    history.push(`/edit/${pageId}`);
  };

  const redactRevision = async (pageId, revisionId) => {
    const pageRef = firestore.collection('pages').doc(pageId);
    await firestore.runTransaction(async (trans) => {
      const page = (await trans.get(pageRef)).data();
      if (page.published && page.published.revisionId === revisionId) {
        page.published = {};
        await trans.update(pageRef, page);
      }
    });
  };

  const publishRevision = async (pageId, revisionId) => {
    const pageRef = firestore.collection('pages').doc(pageId);
    const revisionRef = pageRef.collection('revisions').doc(revisionId);
    await firestore.runTransaction(async (trans) => {
      const page = (await trans.get(pageRef)).data();
      const revision = (await trans.get(revisionRef)).data();
      page.published = {
        revisionId: revisionRef.id,
        text: revision.text,
        title: revision.title,
        styleName: revision.styleName,
      };
      await trans.update(pageRef, page);
    });
  };

  const viewRevision = async (pageId, revisionId) => {
    window.open(`/private/${pageId}/${revisionId}`, '_blank');
  };

  const deleteRevision = async (pageId, revisionId) => {
    const pageRef = firestore.collection('pages').doc(pageId);
    const revisionRef = pageRef.collection('revisions').doc(revisionId);
    await firestore.runTransaction(async (trans) => {
      const page = (await trans.get(pageRef)).data();
      if (page.published && page.published.revisionId === revisionId) {
        page.published = {};
        await trans.update(pageRef, page);
      }
      await trans.delete(revisionRef);
      setResetListeners(true);
    });
  };

  const [selected, setSelected] = useState(null);
  const [selectedModal, setSelectedModal] = useState(false);
  const changeSelected = (pageId, revisionId, pageOrRevision) => {
    const isPage = !revisionId; // if revisionId, then this is not a page
    if (pageId && pageId !== showRevisionsForPageId) {
      setShowRevisionsForPageId(pageId);
    }
    if (isPage && pageId === showRevisionsForPageId) {
      setShowRevisionsForPageId(null);
    }
    if (pageOrRevision) {
      if (isPage && showRevisionsForPageId === pageId) {
        // only show preview on page if expanded
        return;
      }
      setSelected({
        pageId,
        revisionId: revisionId || '',
        title: pageOrRevision.title || '',
        text: pageOrRevision.text || '',
        styleName: pageOrRevision.styleName || '',
      });
      setSelectedModal(true);
    }
  };

  const rows = [];
  Object.entries(pages || {})
    .sort((a, b) => {
      const bb = b[1] ? b[1].working.lastUpdatedOn : 0;
      const aa = a[1] ? a[1].working.lastUpdatedOn : 0;
      return bb - aa;
    })
    .map(([pageId, page]) => {
      rows.push(
        // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
        <dd
          key={pageId}
          onClick={() => changeSelected(pageId, null, page.working)}
          onKeyPress={() => undefined}
        >
          <div className="columns">
            <div className="column col-mr-auto col-auto">
              <button
                type="button"
                className="btn btn-link"
                onClick={() => viewPage(pageId)}
              >
                {selected && !selected.revisionId && selected.pageId === pageId
                  ? (<b>{page.working.title}</b>)
                  : (<>{page.working.title}</>)}
              </button>
            </div>
            <div className="column col-ml-auto col-auto">
              <div className="btn-group">
                <Button
                  className="btn btn-link"
                  text={<i className="fas fa-edit" />}
                  onClick={() => editPage(pageId)}
                />
                <Button
                  className="btn btn-link"
                  text={<i className="fas fa-trash" />}
                  onClick={() => deletePage(firestore, auth, pageId)}
                />
              </div>
            </div>
          </div>
        </dd>,
      );
      if (showRevisionsForPageId !== pageId) {
        return null;
      }
      const { revisions } = page;
      Object.entries(revisions || [])
        .sort((a, b) => b[1].data().createdOn - a[1].data().createdOn)
        .map(([, revisionDoc]) => {
          const revision = revisionDoc.data();
          rows.push(
            // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
            <dd
              key={revisionDoc.id}
              onClick={() => changeSelected(pageId, revisionDoc.id, revision)}
              onKeyPress={() => undefined}
            >
              <div className="columns">
                <div className="column col-mr-auto col-auto">
                  <button
                    type="button"
                    className="btn btn-link"
                    onClick={() => viewRevision(pageId, revisionDoc.id)}
                  >
                    <span>
                      <i className="fas fa-code-branch" style={{ paddingLeft: '10px', paddingRight: '10px' }} />
                      {selected && selected.revisionId && selected.revisionId === revisionDoc.id
                        ? (<b>{moment(revision.createdOn.toDate()).format('YYYY/MM/DD HH:mm:ss')}</b>)
                        : (<>{moment(revision.createdOn.toDate()).format('YYYY/MM/DD HH:mm:ss')}</>)}
                    </span>
                  </button>
                </div>
                <div className="column col-ml-auto col-auto">
                  <div className="btn-group">
                    {page.page.published && revisionDoc.id === page.page.published.revisionId ? (
                      <Button
                        className="btn btn-link"
                        text={<i className="fas fa-paper-plane" />}
                        onClick={() => redactRevision(pageId, revisionDoc.id)}
                      />
                    ) : (
                      <Button
                        className="btn btn-link"
                        text={<i className="far fa-paper-plane" />}
                        onClick={() => publishRevision(pageId, revisionDoc.id)}
                      />
                    )}
                    <Button
                      className="btn btn-link"
                      text={<i className="fas fa-trash" />}
                      onClick={() => deleteRevision(pageId, revisionDoc.id)}
                    />
                  </div>
                </div>
              </div>
            </dd>,
          );
          return null;
        });
      return null;
    });

  const [createPageModalVisible, setCreatePageModalVisible] = useState(false);
  const [isPageTitleValid, setIsPageTitleValid] = useState(true);
  const [newPageTitle, setNewPageTitle] = useState(`Page ${new Date().toISOString()}`);
  const renderNewPageModal = () => (
    <Modal
      className={createPageModalVisible ? 'modal active' : 'modal'}
      onModalClose={() => setCreatePageModalVisible(false)}
      title="Create a Page"
      content={(
        <>
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label>Title</label>
          <Input
            error={!isPageTitleValid}
            placeholder="Page Title"
            value={newPageTitle}
            // onSubmit={async () => {
            //   if (newPageTitle.length > 0 && newPageTitle.match(/^[a-zA-Z0-9-_ :.]+$/)) {
            //     await createNewPage(firestore, auth, newPageTitle);
            //     setCreatePageModalVisible(false);
            //     setNewPageTitle('');
            //   } else {
            //     console.log(`invalid page name ${newPageTitle}`);
            //   }
            // }}
            onChange={(e) => {
              setNewPageTitle(e.target.value);
              if (newPageTitle.match(/^[a-zA-Z0-9-_ ]+$/)) {
                setIsPageTitleValid(true);
              } else {
                setIsPageTitleValid(false);
              }
            }}
          />
          <br />
          <Button
            className="btn btn-block"
            text="Create Page"
            disableDuration={3000}
            onClick={async () => {
              if (newPageTitle.length > 0 && newPageTitle.match(/^[a-zA-Z0-9-_ :.]+$/)) {
                await createNewPage(firestore, auth, newPageTitle);
                setCreatePageModalVisible(false);
                setNewPageTitle('');
              } else {
                console.log(`invalid page name ${newPageTitle}`);
              }
            }}
          />
        </>
      )}
    />
  );

  const renderSelectedModal = () => (
    <Modal
      className={selected ? 'modal active' : 'modal'}
      onModalClose={() => setSelectedModal(false)}
      title={selected ? selected.title : ''}
      content={selected ? (
        <Display
          markdown={selected.text}
          styleName={selected.styleName}
          padding
        />
      ) : undefined}
    />
  );

  return (
    <div className="CatalogPage">
      <div className="columns">
        <div className="column col-mx-auto col-auto text-center">
          <h2>My Pages</h2>
          <Button
            className="btn"
            text="Create New Page"
            disableDuration={50}
            onClick={() => {
              setNewPageTitle(`Page ${new Date().toISOString()}`);
              setCreatePageModalVisible(true);
            }}
          />
          <br />
          <br />
        </div>
      </div>
      <div className="columns">
        <div className={`column col-mx-auto col-8 col-md-10 col-sm-11 col-xs-12${isLoading ? ' loading loading-lg' : ''}`}>
          <dl>
            {rows.map((row) => row)}
          </dl>
        </div>
      </div>
      {selectedModal && renderSelectedModal()}
      {renderNewPageModal()}
    </div>
  );
};
